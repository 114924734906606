exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-cloud-azure-ai-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/my-journey/my-journey/blog/cloud/azure/ai/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-cloud-azure-ai-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-frontend-bootstrap-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/my-journey/my-journey/blog/frontend/bootstrap/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-frontend-bootstrap-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-frontend-gatsby-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/my-journey/my-journey/blog/frontend/gatsby/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-frontend-gatsby-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-problem-solving-leetcode-number-11-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/my-journey/my-journey/blog/problem-solving/leetcode/number11/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-problem-solving-leetcode-number-11-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-problem-solving-leetcode-number-5-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/my-journey/my-journey/blog/problem-solving/leetcode/number5/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-problem-solving-leetcode-number-5-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-programming-language-javascript-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/my-journey/my-journey/blog/programming-language/javascript/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-programming-language-javascript-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-software-engineering-life-cycle-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/my-journey/my-journey/blog/software-engineering/life-cycle/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-software-engineering-life-cycle-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-software-engineering-testing-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/my-journey/my-journey/blog/software-engineering/testing/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-software-engineering-testing-index-mdx" */),
  "component---src-pages-game-index-js": () => import("./../../../src/pages/game/index.js" /* webpackChunkName: "component---src-pages-game-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

